import { Container, Text } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

const ElevatorPitchHero = (): ReactElement => {
  return (
    <Container maxWidth="container.md">
      <Text fontSize="3xl" as="h1" py="16px">
        Hello, I&apos;m Bobby Plunkett
      </Text>
      <Text>
        I&apos;m a software developer who started programming in 2011. I began my professional career in 2014 when I
        joined DreamBot Inc., developing their Java client. I currently specialize in M.E.R.N. (Mongo, Express, React,
        Node) stack applications using Typescript and GraphQL. I have an extensive background in Java, Javascript and
        Typescript, though, have recently begun venturing into Rust as an alternative to NodeJS servers. I have seen
        applications from the design phase to deployment and scaled established applications to meet growing demand. I
        strive to meet deadlines and exceed expectations.
      </Text>
    </Container>
  );
};

export default ElevatorPitchHero;
