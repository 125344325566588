import { Badge, Box } from '@chakra-ui/react';
import humanizeDuration from 'humanize-duration';
import { DateTime } from 'luxon';
import React, { ReactElement } from 'react';

export enum ExperienceType {
  Current = 'Current',
  Previous = 'Previous',
}

interface ExperienceCardProps {
  type: ExperienceType;
  startDate: Date;
  endDate?: Date;
  title: string;
  position: string;
}

const ExperienceCard = ({ type, startDate, endDate, title, position }: ExperienceCardProps): ReactElement => {
  const startDateTime = DateTime.fromJSDate(startDate);
  const endDateTime = DateTime.fromJSDate(endDate ?? new Date());
  return (
    <Box maxW="sm" borderWidth="1px" borderRadius="lg" overflow="hidden">
      <Box p="6">
        <Box mt="1" fontSize="21px" fontWeight="semibold" as="h4" lineHeight="tight" noOfLines={1}>
          {title}
        </Box>

        <Box mb="8px">{position}</Box>
        <Box display="flex" alignItems="baseline">
          <Badge borderRadius="full" px="2" colorScheme={type === ExperienceType.Current ? 'teal' : 'gray'}>
            {type}
          </Badge>
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            ml="2"
          >
            {startDateTime.toFormat('LL/yyyy')}{' '}
            {type === ExperienceType.Previous ? <>- {endDateTime.toFormat('LL/yyyy')} </> : <></>}&bull;{' '}
            {humanizeDuration(endDateTime.diff(startDateTime).milliseconds, { units: ['y', 'mo'], round: true })}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ExperienceCard;
