import { Badge, Flex, Link, Stack, Text, Tooltip } from '@chakra-ui/react';
import { SizeProp } from '@fortawesome/fontawesome-svg-core';
import { faDev, faGithub, faLinkedin, faNpm } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo } from 'react';

import { LogoIcon } from './icons';
import IconSize from './icons/sizes';

const Navbar = () => {
  const width = useMemo(() => window.innerWidth, [window.innerWidth]);
  const logoSize = useMemo<IconSize>(() => (width > 640 ? IconSize.Large : IconSize.Small), [width]);
  const iconSize = useMemo<SizeProp>(() => (width > 640 ? '2x' : 'lg'), [width]);
  return (
    <>
      <Flex justify="space-between" align="center">
        <Flex
          w={`calc(${logoSize}px + 1rem)`}
          h={`calc(${logoSize}px + 1rem)`}
          justify="center"
          align="center"
          id="personal-logo"
        >
          <LogoIcon size={logoSize} />
        </Flex>
        <Stack direction="row" spacing="24px" pr="16px" align="center">
          <Tooltip label="GitHub">
            <Link id="github" href="https://github.com/SkinnyPeteTheGiraffe" isExternal>
              <FontAwesomeIcon icon={faGithub} size={iconSize} />
            </Link>
          </Tooltip>
          <Tooltip label="LinkedIn">
            <Link id="linked-in" href="https://www.linkedin.com/in/bobby-plunkett-024a69166/" isExternal>
              <FontAwesomeIcon icon={faLinkedin} size={iconSize} />
            </Link>
          </Tooltip>
          <Tooltip label="Dev">
            <Link id="dev" href="https://dev.to/skinnypetethegiraffe" isExternal>
              <FontAwesomeIcon icon={faDev} size={iconSize} />
            </Link>
          </Tooltip>
          <Tooltip label="NPM">
            <Link id="npm" href="https://www.npmjs.com/~notoriouspp" isExternal>
              <FontAwesomeIcon icon={faNpm} size={iconSize} />
            </Link>
          </Tooltip>
          {width > 640 ? (
            <Text py="8px">
              Available:{' '}
              <Badge borderRadius="full" px="2" colorScheme="red">
                No
              </Badge>
            </Text>
          ) : (
            <div />
          )}
        </Stack>
      </Flex>
      <hr />
    </>
  );
};

export default Navbar;
