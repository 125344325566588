import { Box } from '@chakra-ui/react';
import React from 'react';

import { Navbar } from './commons';
import { ElevatorPitchHero, ExperienceHero } from './home';
import SkillHero from './home/components/skills/SkillHero';

function App() {
  return (
    <div>
      <Navbar />
      <ElevatorPitchHero />
      <Box my="32px" />
      <ExperienceHero />
      <SkillHero />
    </div>
  );
}

export default App;
