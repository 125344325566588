import { Box } from '@chakra-ui/react';
import humanizeDuration from 'humanize-duration';
import { DateTime } from 'luxon';
import React, { ReactElement } from 'react';

import SkillBadge from './SkillBadge';
import { SkillType } from './skillType';

interface SkillCardProps {
  title: string;
  startYear: number;
  lastYear?: number;
  type: SkillType;
}

const SkillCard = ({ title, type, startYear, lastYear }: SkillCardProps): ReactElement => {
  const duration = (lastYear ? DateTime.fromObject({ year: lastYear }) : DateTime.now()).diff(
    DateTime.fromObject({ year: startYear }),
  );
  return (
    <Box maxW="sm" borderWidth="1px" borderRadius="lg" overflow="hidden">
      <Box p="6">
        <Box mt="1" fontSize="21px" fontWeight="semibold" as="h4" lineHeight="tight" noOfLines={1}>
          {title}
        </Box>
        <Box display="flex" alignItems="baseline">
          <SkillBadge type={type} />
          <Box
            color="gray.500"
            fontWeight="semibold"
            letterSpacing="wide"
            fontSize="xs"
            textTransform="uppercase"
            ml="2"
          >
            {humanizeDuration(duration.milliseconds, { round: true, units: ['y', 'mo'] })}{' '}
            {lastYear ? <>&bull; Last Used: {lastYear}</> : <></>}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SkillCard;
