import { extendTheme } from '@chakra-ui/react';

const colors = {
  brand: {
    900: '#04c093',
    800: '#17be96',
    700: '#29be9b',
  },
};

export default extendTheme({ colors });
