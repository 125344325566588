import { css } from '@emotion/react';
import React from 'react';

import IconSize from './sizes';

interface LogoProps {
  fill?: string;
  size?: IconSize;
}

const Logo = ({ fill = '#2d2d2d', size = IconSize.Small }: LogoProps) => {
  return (
    <svg
      width={`${size}px`}
      height={`${size}px`}
      viewBox="0 0 923 873"
      version="1.1"
      data-icon="personal-logo"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlSpace="preserve"
      css={css`
        fill-rule: evenodd;
        clip-rule: evenodd;
        stroke-linejoin: round;
        stroke-miterlimit: 2;
      `}
    >
      <g transform="matrix(1,0,0,1,-98.5004,-125.803)">
        <g transform="matrix(6.4218,0,0,2.14207,-1737.93,-829.57)">
          <g id="Icon">
            <g>
              <g transform="matrix(1,0,0,1,-80.5767,2.27374e-13)">
                <path
                  d="M469.327,446.005L425.061,446.005L425.061,796.548L451.735,796.391L451.735,686.22L469.327,686.377C492.378,686.377 510.252,632.794 510.252,566.191C510.252,499.588 492.378,446.005 469.327,446.005ZM469.327,611.261L451.788,611.261L451.788,521.121L469.327,521.121C477.512,521.121 483.525,540.651 483.525,566.191C483.525,591.731 477.512,611.261 469.327,611.261Z"
                  css={css`
                    fill: ${fill};
                    fill-rule: nonzero;
                  `}
                />
              </g>
              <g transform="matrix(-1,0,0,1,796.22,0)">
                <path
                  d="M469.327,446.005L425.061,446.005L425.061,796.548L451.788,796.548L451.788,686.377L469.327,686.377C492.378,686.377 510.252,632.794 510.252,566.191C510.252,499.588 492.378,446.005 469.327,446.005ZM469.327,611.261L451.788,611.261L451.788,521.121L469.327,521.121C477.512,521.121 483.525,540.651 483.525,566.191C483.525,591.731 477.512,611.261 469.327,611.261Z"
                  css={css`
                    fill: ${fill};
                    fill-rule: nonzero;
                  `}
                />
              </g>
              <g transform="matrix(-1,0,0,-1,796.22,1299.28)">
                <path
                  d="M469.327,446.005L425.061,446.005L425.061,796.548L451.788,796.548L451.788,686.377L469.327,686.377C492.378,686.377 510.252,632.794 510.252,566.191C510.252,499.588 492.378,446.005 469.327,446.005ZM469.327,611.261L451.788,611.261L451.788,521.121L469.327,521.121C477.512,521.121 483.525,540.651 483.525,566.191C483.525,591.731 477.512,611.261 469.327,611.261Z"
                  css={css`
                    fill: ${fill};
                    fill-rule: nonzero;
                  `}
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Logo;
