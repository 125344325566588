import { Badge, ThemeTypings } from '@chakra-ui/react';
import React, { ReactElement, useMemo } from 'react';

import { SkillType } from './skillType';

interface SkillBadgeProps {
  type: SkillType;
}

const getColorForType = (type: SkillType): ThemeTypings['colorSchemes'] => {
  switch (type) {
    case SkillType.Learning:
      return 'red';
    case SkillType.Sometimes:
      return 'green';
    case SkillType.Active:
      return 'blue';
    case SkillType.Previous:
      return 'purple';
  }
};

const SkillBadge = ({ type }: SkillBadgeProps): ReactElement => {
  const color: ThemeTypings['colorSchemes'] = useMemo(() => getColorForType(type), [type]);
  return (
    <Badge borderRadius="full" px="2" colorScheme={color}>
      {type}
    </Badge>
  );
};

export default SkillBadge;
