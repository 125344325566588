import { Container, SimpleGrid, Text } from '@chakra-ui/react';
import { DateTime } from 'luxon';
import React, { ReactElement, useMemo } from 'react';

import ExperienceCard, { ExperienceType } from './ExperienceCard';

const ExperienceHero = (): ReactElement => {
  const totalDuration = useMemo(() => DateTime.now().diff(DateTime.fromObject({ year: 2014, month: 6, day: 14 })), []);
  return (
    <Container maxWidth="container.md" mb="40px">
      <Text fontSize="2xl" as="h2" py="16px">
        My Experience ({totalDuration.toFormat('y')} years)
      </Text>
      <SimpleGrid minChildWidth="240px" spacing="40px">
        <ExperienceCard
          type={ExperienceType.Current}
          startDate={DateTime.fromObject({ year: 2020, day: 13, month: 7 }).toJSDate()}
          title="Charter Communications"
          position="Senior Software Engineer"
        />
        <ExperienceCard
          type={ExperienceType.Previous}
          startDate={DateTime.fromObject({ year: 2019, day: 20, month: 5 }).toJSDate()}
          endDate={DateTime.fromObject({ year: 2020, day: 29, month: 6 }).toJSDate()}
          title="CherryTree Inc."
          position="Fullstack Developer"
        />
        <ExperienceCard
          type={ExperienceType.Previous}
          startDate={DateTime.fromObject({ year: 2014, day: 14, month: 6 }).toJSDate()}
          endDate={DateTime.fromObject({ year: 2020, day: 20, month: 5 }).toJSDate()}
          title="DreamBot Inc."
          position="Java Developer"
        />
      </SimpleGrid>
    </Container>
  );
};

export default ExperienceHero;
