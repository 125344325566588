import { Container, SimpleGrid, Text } from '@chakra-ui/react';
import React from 'react';

import SkillCard from './SkillCard';
import { SkillType } from './skillType';

const SkillHero = () => {
  return (
    <Container maxWidth="container.md" mb="40px">
      <Text fontSize="2xl" as="h3" py="16px">
        Things I&apos;m Good At
      </Text>
      <SimpleGrid minChildWidth="240px" spacing="40px">
        <SkillCard type={SkillType.Previous} title="Java" startYear={2012} lastYear={2019} />
        <SkillCard type={SkillType.Active} title="Javascript" startYear={2016} />
        <SkillCard type={SkillType.Active} title="Typescript" startYear={2020} />
        <SkillCard type={SkillType.Active} title="GraphQL" startYear={2019} />
        <SkillCard type={SkillType.Active} title="MongoDB" startYear={2019} />
        <SkillCard type={SkillType.Sometimes} title="Python" startYear={2018} />
        <SkillCard type={SkillType.Sometimes} title="C#" startYear={2016} />
        <SkillCard type={SkillType.Learning} title="Rust" startYear={2022} />
      </SimpleGrid>
    </Container>
  );
};

export default SkillHero;
